import Alpine from 'alpinejs';
/*import Validator from 'validatorjs';*/
import wizard from '@glhd/alpine-wizard';
import Swiper from "swiper";
import { Navigation, Pagination, A11y, EffectCards } from 'swiper/modules';
import './nav';

/*
 *  Alpine.js
 */
Alpine.plugin(wizard);
window.Alpine = Alpine;
Alpine.start();

/*
 *  Swiper
 */
const swiper = new Swiper('.swiper', {
    modules: [Navigation, Pagination, A11y, EffectCards],

    // Optional parameters
    direction: 'horizontal',
    loop: true,
    effect: 'cards',

    // If we need pagination
    pagination: {
        el: '.swiper-pagination',
    },

    // Navigation arrows
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },

});

/* Set min date on all date pickers */
const datePickers = document.querySelectorAll('input[type="date"]');
const today = new Date().toISOString().split('T')[0]; // Today's date in format YYYY-MM-DD
datePickers.forEach(function(datePicker) {
    datePicker.min = today;
});


/* Autoplay videos when visible */
const videos = document.querySelectorAll('video.autoplay');

const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
        if (entry.isIntersecting) {
            entry.target.play();
        } else {
            entry.target.pause();
        }
    });
});

videos.forEach(video => {
    observer.observe(video);
});

/* Scroll booking form to top when going forward/backward */
if (window.innerWidth < 640) {
    document.querySelectorAll('#bookingButtons button').forEach(button => {
        button.addEventListener('click', () => {
            document.getElementById('book').scrollIntoView({ behavior: 'instant' });
        });
    });
}

